$(function() {
	$('#prefecture_id').change(function() {
		$.get({
			url: "/homes/set_companies",
			data: { prefecture_id: $('#prefecture_id').has('option:selected').val() }
		});
	});
	$('#prefecture_id_for_sp').change(function() {
		$.get({
			url: "/homes/set_cities",
			data: { prefecture_id: $('#prefecture_id_for_sp').has('option:selected').val() }
		});
	});
});

$(function() {
	$('#city_id').change(function() {
		$.get({
			url: "/homes/set_companies",
			data: { prefecture_id: $('#prefecture_id').has('option:selected').val(), city_id: $('#city_id').has('option:selected').val() }
		});
	});
});

$(function() {
	var industry_ids = []
	var feature_ids = []
	
	$('.industries li').click(function(){
		var prefecture_id = $('#prefecture_id').has('option:selected').val()
		var city_id = $('#city_id').has('option:selected').val()
		var industry_id = $(this).prop('id').replace('industry_','');
		var industry_id_arrays = [];
		industry_id_arrays.push(industry_id)

		if(industry_ids.includes(industry_id)){
			industry_ids = industry_ids.filter(function(e) { 
				if(e === industry_id){
					$("#industry_" + industry_id).children('.fas').removeClass('selected')
				}
				return e !== industry_id
			})
		}else{
			industry_ids.push(industry_id)
			$("#industry_" + industry_id).children('.fas').addClass('selected')
		}

		$.get({
			url: "/homes/set_companies",
			data: { industry_ids: industry_ids, feature_ids: feature_ids, prefecture_id: prefecture_id, city_id: city_id }
		});
	});

	$('.features li').click(function(){
		var prefecture_id = $('#prefecture_id').has('option:selected').val()
		var city_id = $('#city_id').has('option:selected').val()
		var feature_id = $(this).prop('id').replace('feature_','');
		var feature_id_arrays = [];
		feature_id_arrays.push(feature_id)

		if(feature_ids.includes(feature_id)){
			feature_ids = feature_ids.filter(function(e) { 
				if(e === feature_id){
					$("#feature_" + feature_id).children('.fas').removeClass('selected')
				}
				return e !== feature_id
			})
		}else{
			feature_ids.push(feature_id)
			$("#feature_" + feature_id).children('.fas').addClass('selected')
		}

		$.get({
			url: "/homes/set_companies",
			data: { industry_ids: industry_ids, feature_ids: feature_ids, prefecture_id: prefecture_id, city_id: city_id }
		});
	});
});

$(function() {
	var industry_ids = []
	var feature_ids = []

	$('.industries_for_sp li label').on('click', function(){
		var industry_id = $(this).parent().attr('id').replace('industry_for_sp_','');
		var industry_id_arrays = [];
		industry_id_arrays.push(industry_id)

		if(industry_ids.includes(industry_id)){
			industry_ids = industry_ids.filter(function(e) { 
				if(e === industry_id){
					$("#industry_for_sp_" + industry_id).children('.fas').removeClass('selected')
				}
				return e !== industry_id
			})
		}else{
			industry_ids.push(industry_id)
			$("#industry_for_sp_" + industry_id).children('.fas').addClass('selected')
		}
	});

	$('.features_for_sp li label').on('click', function(){
		var feature_id = $(this).parent().attr('id').replace('feature_for_sp_','');
		var feature_id_arrays = [];
		feature_id_arrays.push(feature_id)

		if(feature_ids.includes(feature_id)){
			feature_ids = feature_ids.filter(function(e) { 
				if(e === feature_id){
					$("#feature_for_sp_" + feature_id).children('.fas').removeClass('selected')
				}
				return e !== feature_id
			})
		}else{
			feature_ids.push(feature_id)
			$("#feature_for_sp_" + feature_id).children('.fas').addClass('selected')
		}
	});
});
