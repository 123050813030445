$(function() {
  $('.slider').slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    variableWidth: true,
    centerMode: true,
    prevArrow: '<i class="fas fa-chevron-circle-left"></i>',
    nextArrow: '<i class="fas fa-chevron-circle-right"></i>'
  });
});